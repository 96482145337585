import BaseAction from '@/actions/base_action.js'

export default class extends BaseAction {
  execute(properties, component) {
    this.executePromise(
      this.constructor._promise(properties.snapshotCount, component).then(() => {
        // Refresh the scope's progress percentage.
        // Pass ifApplicable=true because this may be called from outside of `projects#show`.
        this.refreshProjectShow(true);
      }),
      properties,
      component,
      true)
  }

  static async _promise(snapshotCount, component) {
    const hillChart = GLib.component.findById('hill_chart')

    // `hillChart` will not exist if the snapshot is created programatically, e.g. during the creation
    // of a project.
    if (!hillChart) {
      return
    }

    const t = TrelloPowerUp.iframe()
    const points = hillChart.getSnapshotPoints();

    await Promise.all(points.map((point) => {
      const cardId = point.key
      return $tpu.r.cards.setData(cardId, { [$tpu.keys.crdHillPointX()]: point.x }, t)
    }))

    GLib.action.execute({
      "action": "snackbars/alert",
      "styleClasses": ["success"],
      "message": "Saved"
    }, component)

    hillChart.action_merge({
      snapshotCount: snapshotCount
    })

    Utils.http.clearDirtyState();
    hillChart.clearDirtyState();

    GLib.action.execute({
      "action": "fields/reset",
      "targetId": "snapshot_description"
    }, component)

  }
}
